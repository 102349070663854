import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingView } from '.';
import { ACCESS_TOKEN_KEY, api } from '../api';
import { CheckoutSessionStatusEnum } from '../generated';
import { useQueryClient } from '@tanstack/react-query';
import { useTickets } from '../api/queries';
import { handleinteractions } from '../helpers';

export const CompletePaymentView = () => {
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const tickets = useTickets();

  useEffect(() => {
    const checkSessionStatus = async () => {
      const sessionId = searchParams.get('session_id');
      const accessToken = searchParams.get('access_token');
      if (!sessionId) return;

      try {
        const { data } = await api.paymentsControllerConfirmSession({ sessionId });
        if (data.status === CheckoutSessionStatusEnum.Complete) {
          const { data: user } = await api.usersControllerFindMe({
            headers: { Authorization: `Bearer ${accessToken}` },
          });

          if (user) {
            localStorage.setItem(ACCESS_TOKEN_KEY, accessToken as string);
            queryClient.invalidateQueries(['currentUser']);

            await tickets.refetch();

            setTimeout(() => {
              navigate('/dashboard/paid');
            }, 500);
          }
          setLoading(false);
        } else {
          setError('Payment not complete');
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setError('Payment not complete');
        setLoading(false);
      }
    };
    checkSessionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (loading) return <LoadingView />;
  if (error)
    return (
      <>
        <div className="min-h-screen flex flex-col justify-center items-center">
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
              <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-400" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-base font-semibold leading-6 text-gray-100">
                Something went wrong 😢
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-300">{error}</p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <button
              type="button"
              {...handleinteractions(() => navigate('/dashboard'))}
              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back
            </button>
          </div>
        </div>
      </>
    );

  return <Navigate to={`/dashboard/paid`} />;
};
