import { cn } from '../utils/css';

export const Spinner = ({ size = 'base' }: { size?: 'big' | 'base' | 'small' }) => {
  return (
    <div
      className={cn('animate-spin rounded-full', {
        'h-16 w-16 border-t-4': size === 'big',
        'h-12 w-12 border-t-2': size === 'base',
        'h-8 w-8 border-t-2': size === 'small',
      })}
    />
  );
};
