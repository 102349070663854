import { ExpressCheckoutElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeExpressCheckoutElementOptions } from '@stripe/stripe-js';
import { useState } from 'react';
import { PaymentIntent } from '../generated';

interface Props {
  onConfirm: () => void;
  paymentIntent: PaymentIntent;
}

export const CheckoutFormExpress = ({ onConfirm, paymentIntent }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>();

  const paymentElementOptions: StripeExpressCheckoutElementOptions = {
    paymentMethodOrder: ['apple_pay', 'google_pay', 'paypal', 'amazon_pay'],
    layout: {
      maxColumns: 1,
    },
    paymentMethods: {
      amazonPay: 'never',
      paypal: 'never',
      applePay: 'auto',
      googlePay: 'auto',
      link: 'never',
    },
  };

  const handleConfirm = async () => {
    if (!stripe || !elements) return;

    const { error } = await stripe.confirmPayment({
      elements: elements ?? undefined,
      clientSecret: paymentIntent.client_secret,
      redirect: 'if_required',
    });

    if (error) {
      setMessage(error.message);
    } else {
      onConfirm();
    }
  };

  return (
    <div>
      <ExpressCheckoutElement options={paymentElementOptions} onConfirm={handleConfirm} />
      {message && (
        <div
          id="payment-message"
          style={{
            marginTop: 10,
            border: '1px solid red',
            color: 'red',
            textAlign: 'center',
            padding: 10,
          }}
        >
          {message}
        </div>
      )}
    </div>
  );
};
