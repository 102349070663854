import { useEffect, useRef, useState } from 'react';

export const ChatView = () => {
  const [chatUnavailable, setChatUnavailable] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    new ZammadChat({
      background: '#1DA2DC',
      fontSize: '12px',
      chatId: 1,
      flat: true,
      show: true,
      debug: true,
      target: $('#chat-root'),
    });

    let count = 0;
    const interval = setInterval(() => {
      count++;
      if (count > 10) {
        clearInterval(interval);
        setChatUnavailable(true);
      }
      if (ref.current?.style.display === 'none') {
        clearInterval(interval);
        setChatUnavailable(true);
      } else {
        setChatUnavailable(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="h-screen flex justify-center items-center">
        {chatUnavailable && (
          <p className="text-xl mb-4 text-white">Chat is currently unavailable</p>
        )}
        <button
          ref={ref}
          className="bg-blue-500 text-white py-2 px-6 text-lg rounded hover:bg-blue-600"
        >
          Chat with us
        </button>
      </div>
      <div id="chat-root"></div>
    </>
  );
};
