import { Spinner } from '../components/spinner';

export const LoadingView = ({ message }: { message?: string }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-bgPrimary text-white z-50">
      <div className="flex flex-col items-center justify-center gap-5">
        <Spinner size="big" />
        <h1 className="text-4xl">{message || 'Loading...'}</h1>
      </div>
    </div>
  );
};
