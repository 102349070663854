import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { Heading } from './heading';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cn } from '../../utils/css';
import React from 'react';
import { handleinteractions } from '../../helpers';

export const RouteNavigation = ({
  label,
  route,
  sticky = false,
  actionComponent,
}: {
  label: string;
  route: string;
  sticky?: boolean;
  actionComponent?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn('flex justify-between p-4 bg-bgPrimary z-10', sticky ? 'sticky top-0  ' : '')}
    >
      <BackButton route={route} />
      <Heading level={2}>{t(label)}</Heading>
      {actionComponent || <div className="size-10 select-none" />}
    </div>
  );
};

export const BackButton = ({ route }: { route: string }) => {
  const navigate = useNavigate();
  return (
    <div className="rounded-full bg-textSecondary/10 size-10 grid place-content-center">
      <ArrowUturnLeftIcon
        className="size-6 text-white"
        {...handleinteractions(() => navigate(route))}
      />
    </div>
  );
};
