import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../api';
import { LoadingView } from './loading-view';
import { handleinteractions } from '../helpers';

export const RedirectView = () => {
  const params = useParams<{ shortcode: string }>();
  const navigate = useNavigate();

  const query = useQuery({
    queryKey: ['redirect'],
    queryFn: async () =>
      (
        await api.redirectsControllerRedirectToOriginal({
          code: params.shortcode as string,
        })
      ).data,
    onSuccess: ({ url }) => {
      window.location.href = url;
    },
  });

  if (query.isError)
    return (
      <>
        <div className="min-h-screen flex flex-col justify-center items-center">
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
              <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-400" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-base font-semibold leading-6 text-gray-100">
                Something went wrong 😢
              </h3>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <button
              type="button"
              {...handleinteractions(() => navigate('/dashboard'))}
              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back
            </button>
          </div>
        </div>
      </>
    );

  return <LoadingView />;
};
