import { getCountryCallingCode } from 'react-phone-number-input';
import { getFlagEmoji } from '../../helpers';
import { Select } from '../ui/select';
import { CountryCode } from 'libphonenumber-js';

interface CountryProps {
  options: {
    value?: string;
    label: string;
  }[];
  value?: string;
  onChange: (value: string) => void;
}

const preferredCountries = ['SE', 'NO', 'DK', 'FI', 'DE', 'US', 'GB', 'CH', 'CN', 'ES', 'FR'];

export const CountrySelect = (props: CountryProps) => {
  // const { t } = useTranslation();
  const sortedOptions = props.options.sort((a, b) => {
    const aIndex = preferredCountries.indexOf(a.value || '');
    const bIndex = preferredCountries.indexOf(b.value || '');
    return aIndex !== -1 && bIndex !== -1 ? aIndex - bIndex : aIndex === -1 ? 1 : -1;
  });

  return (
    <div className="max-w-[82px]">
      <Select value={props.value} onChange={(e) => props.onChange(e.target.value)}>
        {sortedOptions.map((option) => (
          <option key={option.label} value={option.value}>
            {getFlagEmoji(option.value || '')}
            &emsp;
            {option.label}
            {option.value && `(+${getCountryCallingCode(option.value as CountryCode)})`}
          </option>
        ))}
      </Select>
    </div>
  );
};
