import { useNavigate, useSearchParams } from 'react-router-dom';
import { useWindowSize } from '../hooks/use-window-size';
import { useEffect, useState } from 'react';
import { ACCESS_TOKEN_KEY, api, createAuthHeader } from '../api';
import { useQueryClient } from '@tanstack/react-query';
import { useGetCurrentUser } from '../api/queries';
import { LoadingView } from './loading-view';
import { handleinteractions } from '../helpers';

export const SmsLinkView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { height } = useWindowSize();
  const [params] = useSearchParams();
  const [message, setMessage] = useState('');
  const queryClient = useQueryClient();
  const { isError } = useGetCurrentUser();

  const token = params.get('token');

  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setMessage('Invalid token');
        setLoading(false);
        return;
      }

      try {
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
        const { data } = await api.usersControllerFindMe({
          headers: {
            Authorization: createAuthHeader(token),
          },
        });
        if (data?.accessToken) {
          await queryClient.invalidateQueries(['currentUser']);
          setTimeout(() => {
            navigate('/dashboard');
          }, 2000);
        } else {
          throw new Error('Invalid token');
        }
      } catch (error) {
        console.error(error);
        setMessage('Link has expired or is invalid...');
        setLoading(false);
      }
    };

    verifyToken();
  }, [token, navigate, queryClient]);

  return (
    <div className="flex flex-col justify-start bg-bgPrimary" style={{ height: `${height}px` }}>
      {loading || !isError ? (
        <LoadingView />
      ) : (
        <div className="container mx-auto max-w-xs mt-10 text-center">
          <h1 className="text-4xl text-white mb-4">
            {message || 'Something went wrong. Please try again.'}
          </h1>
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded w-full"
            {...handleinteractions(() => (window.location.href = '/'))}
          >
            Go to Home
          </button>
        </div>
      )}
    </div>
  );
};
