import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import { PaymentIntent } from '../generated';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { Spinner } from './spinner';
import { useTranslation } from 'react-i18next';

interface Props {
  onConfirm: () => void;
  paymentIntent: PaymentIntent;
  showCard: boolean;
  setShowCard: Dispatch<SetStateAction<boolean>>;
}

export const CheckoutFormElements = ({ onConfirm, showCard, setShowCard }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    e.preventDefault();
    setMessage(undefined);
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      setMessage(error?.message);
      setIsLoading(false);
      return;
    }

    onConfirm();
    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'accordion',
      radios: false,
    },
    paymentMethodOrder: ['card', 'apple_pay', 'google_pay', 'link'],
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div
        style={{
          display: 'grid',
          gridTemplateRows: showCard ? '1fr' : '0',
          overflow: 'hidden',
          transition: 'all 1s ease-out',
          WebkitTransition: 'all 1s ease-out',
          marginBottom: 10,
        }}
      >
        <PaymentElement id="payment-element" options={paymentElementOptions} />
      </div>
      {message && (
        <div
          id="payment-message"
          style={{
            marginTop: 10,
            border: '1px solid red',
            color: 'red',
            textAlign: 'center',
            padding: 10,
          }}
        >
          {message}
        </div>
      )}
      <button
        disabled={isLoading || !stripe || !elements}
        type="submit"
        onClick={(e) => {
          if (!showCard) {
            e.preventDefault();
            setShowCard(true);
          }
        }}
        className="items-center w-full gap-x-2 rounded-md bg-black px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 flex flex-row justify-center"
      >
        <span id="button-text">{isLoading ? <Spinner size="small" /> : t('payments.card')}</span>
        <CreditCardIcon aria-hidden="true" className="-mr-0.5 h-5 w-5" />
      </button>
    </form>
  );
};
