import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePhoneInit, usePhoneSignIn } from '../api/mutations';

export const usePhoneAuth = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>();

  const { isLoading: initLoading, mutate: init } = usePhoneInit();
  const { mutate: signIn, isLoading: signInloading } = usePhoneSignIn();

  const navigate = useNavigate();

  const handleInit = (phone: string) => {
    setPhoneNumber(phone);
    init(phone);
    navigate('/phone/otp', {
      state: { phone },
    });
  };

  const handleSignIn = (phone: string, code: string) => {
    if (!phone || !code) return;
    signIn({ phone: phone, otp: code });
  };

  const handleRetry = () => {
    navigate('/phone');
  };

  return {
    handleSignIn,
    handleRetry,
    phoneNumber,
    handleInit,
    isLoading: initLoading || signInloading,
  };
};
