import { useTranslation } from 'react-i18next';
import { OTPForm } from '../ui/otp-input';
import { Button } from '../ui/button';
import React from 'react';
import { Description, Field, Label } from '../ui/fieldset';
import { Divider } from '../ui/divider';
import { Text } from '../ui/text';
import { handleinteractions } from '../../helpers';

interface VerificationCodeFormProps {
  onSubmit: (phoneNumber: string, verificationCode: string) => void;
  onRetry: () => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export function VerificationCodeForm({ onSubmit, onRetry }: VerificationCodeFormProps) {
  const { t } = useTranslation();

  return (
    <Field className="w-full flex items-center flex-col">
      <Label>{t('verification.header')}</Label>
      <Description>{t('verification.subheader')}</Description>
      <OTPForm onComplete={onSubmit} />
      <Divider soft className="mb-8" />
      <Text>{t('verification.body1')}</Text>
      <Button className="mt-2" {...handleinteractions(onRetry)}>
        {t('verification.body2')}
      </Button>
    </Field>
  );
}
