export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function getFlagEmoji(countryCode: string): string {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));

  return String.fromCodePoint(...codePoints);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleinteractions = (handler: (args: any) => void) => {
  const isMobile = 'ontouchstart' in window;
  if (!isMobile) {
    return { onMouseDown: handler };
  }

  return {
    onTouchStart: handler,
  };
};
