import {
  ArrowPathIcon,
  CheckIcon,
  ChevronUpDownIcon,
  CreditCardIcon,
} from '@heroicons/react/24/outline';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { api } from '../api';
import { classNames, handleinteractions } from '../helpers';
import { useDebounce } from '../hooks/use-debounce';
import { TICKET_PURCHASE_LIMIT } from '../utils/constants';
import { plausible, trackEvent } from '../utils/track-events';
import { DashboardContext } from '../views';
import { Dialog } from './dialog';
import { Spinner } from './spinner';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutFormElements } from './checkout-form-elements';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { loadStripe, StripeElementsOptionsClientSecret } from '@stripe/stripe-js';
import { CheckoutFormExpress } from './checkout-form-express';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import { FacilityForPayments } from '../generated';

interface Props {
  open: boolean;
  onClose: () => void;
}

const stripePromise = loadStripe(
  import.meta.env.VITE_STRIPE_PK ||
    'pk_test_51OgZqKE26LEtC5Eq9TNj0Dm62zLIYBjuXqaW8Xmtgq7HTq1dAA6rfDwdJibZeqBv61uq9hae7SbOrDeCsSLsEZcG00GrhpWg5H',
);

const stripeTestPromise = loadStripe(
  import.meta.env.VITE_STRIPE_TEST_PK ||
    'pk_test_51OgZqKE26LEtC5Eq9TNj0Dm62zLIYBjuXqaW8Xmtgq7HTq1dAA6rfDwdJibZeqBv61uq9hae7SbOrDeCsSLsEZcG00GrhpWg5H',
);

export const PaymentDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();

  const [numberOfTickets, setNumberOfTickets] = useState(1);
  const [tempNumberOfTickets, setTempNumberOfTickets] = useState(1);
  const [showCard, setShowCard] = useState(false);

  const [loading, setLoading] = useState(false);

  const ticketMissmatch = tempNumberOfTickets !== numberOfTickets;

  const [query, setQuery] = useState('');
  const [selectedFacility, setSelectedFacility] = useState<FacilityForPayments>();

  const { data: facilitiesForPayments } = useQuery({
    queryKey: ['facilities', '-for-payments'],
    queryFn: async () => (await api.facilitiesControllerGetFacilitiesForPayments()).data,
    initialData: [],
  });

  const filteredFacilities =
    query === ''
      ? facilitiesForPayments
      : facilitiesForPayments.filter((facility) => {
          return (
            facility.name.toLowerCase().includes(query.toLowerCase()) ||
            facility.paymentCode.toLowerCase().includes(query.toLowerCase())
          );
        });

  const {
    paymentCode,
    setPaymentCode,
    paymentInitiated,
    setPaymentInitiated,
    checkoutSession,
    setCheckoutSession,
    setMessage,
    handlePaymentCode,
    paymentCodeError,
    setPaymentCodeError,
    paymentIntent,
  } = useContext(DashboardContext);

  const navigate = useNavigate();

  const handleSwish = async () => {
    setLoading(true);
    try {
      const { data: facility } = await api.facilitiesControllerGetFacilityByName({
        facilityName: paymentCode as string,
      });

      const { data } = await api.paymentsControllerInitSwishForFacility({
        facilityId: facility.id,
        initPaymentDto: { numberOfTickets },
      });

      clearPaymentCode();

      const appUrl = `swish://paymentrequest?token=${data.swishToken}&callbackurl=${window.location.origin}/swish/status?statusCheckToken=${data.statusCheckToken}`;
      window.location.href = appUrl;
    } catch (error) {
      setMessage('Något gick fel');
      setLoading(false);
    }
  };

  useEffect(() => {
    const paymentCodeLocalStorage = localStorage.getItem('paymentCode');
    if (paymentCodeLocalStorage) {
      setPaymentCode(paymentCodeLocalStorage);
      handlePaymentCode(paymentCodeLocalStorage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearPaymentCode = () => {
    const paymentCodeLocalStorage = localStorage.getItem('paymentCode');
    if (paymentCodeLocalStorage) {
      localStorage.removeItem('paymentCode');
    }
    setPaymentCode('');
  };

  const handleClose = () => {
    clearPaymentCode();
    onClose();
    setMessage('');
    setLoading(false);
    setPaymentInitiated(false);
    setCheckoutSession(undefined);
    setNumberOfTickets(1);
    setTempNumberOfTickets(1);
    setShowCard(false);
    setSelectedFacility(undefined);
    if (setPaymentCodeError) setPaymentCodeError(false);
    navigate('/dashboard/paid');
  };

  const prefillPaymentCode = localStorage.getItem('prefillPaymentCode');
  const prefillFacility = facilitiesForPayments.find(
    (facility) => facility.paymentCode === prefillPaymentCode,
  );

  const queryClient = useQueryClient();

  const handleConfirm = async () => {
    setLoading(true);

    try {
      const { data } = await api.paymentsControllerConfirmPaymentIntent({
        paymentIntentId: paymentIntent?.paymentIntent?.id as string,
      });

      if (['succeeded', 'paid'].includes(data.status as unknown as string)) {
        await queryClient.invalidateQueries(['qr-code']);
        handleClose();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeNumberOfTickets(Number(event.target.value));
  };

  const handleButtonPress = (i: number) => {
    changeNumberOfTickets(i);
  };

  const handleTempNumberOfTickets = (i: number) => {
    let val = i;

    if (val > TICKET_PURCHASE_LIMIT) {
      val = TICKET_PURCHASE_LIMIT;
    }
    setTempNumberOfTickets((prev) => prev + val);
  };

  const debouncedHandleInputChange = useDebounce(handleInputChange, 1000);
  const debouncedHandleButtonPress = useDebounce(handleButtonPress, 1000);

  const changeNumberOfTickets = (i: number) => {
    let numberOftickets = i;
    if (numberOftickets <= 0) {
      setNumberOfTickets(1);
      setTempNumberOfTickets(1);
      return;
    }
    if (i > TICKET_PURCHASE_LIMIT) {
      numberOftickets = TICKET_PURCHASE_LIMIT;
    }
    setNumberOfTickets(numberOftickets);
    setTempNumberOfTickets(numberOftickets);
    setLoading(true);
    handlePaymentCode(paymentCode, numberOftickets).finally(() => setLoading(false));
  };

  const options: StripeElementsOptionsClientSecret = {
    clientSecret: paymentIntent?.paymentIntent?.client_secret || '',
    appearance: {
      theme: 'stripe',
    },
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="rounded-lg flex flex-col gap-5 min-w-[224px]">
        {!checkoutSession && paymentInitiated ? (
          <div className="m-auto p-4">
            <Spinner />
          </div>
        ) : checkoutSession ? (
          <>
            <div>
              <div className="p-4 text-center">
                <h5 className="text-xl font-medium text-black">
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  {checkoutSession.checkoutSession.metadata.facilityName} <br />
                  {checkoutSession.checkoutSession.amount_total / 100}{' '}
                  {checkoutSession.checkoutSession.currency}
                </h5>
                <p className="text-black">Antal biljetter {numberOfTickets}</p>
              </div>
              <div className="flex w-full flex-1 p-4 justify-center items-center">
                <button
                  {...handleinteractions(() => {
                    debouncedHandleButtonPress(tempNumberOfTickets - 1);
                    handleTempNumberOfTickets(-1);
                  })}
                  className={classNames(
                    'h-10 w-10 border border-primary-main text-primary-main bg-red-700 text-white rounded-md grid place-content-center text-2xl',
                    tempNumberOfTickets <= 1 ? 'pointer-events-none opacity-30' : '',
                  )}
                >
                  &#45;
                </button>
                <div className="flex justify-center items-center w-full flex-1 min-w-[96px]">
                  <input
                    type="number"
                    min={0}
                    max={TICKET_PURCHASE_LIMIT}
                    className="text-center text-2xl w-16 border-0 bg-transparent focus:ring-0"
                    disabled={loading}
                    value={tempNumberOfTickets}
                    onChange={(e) => {
                      debouncedHandleInputChange(e as ChangeEvent<HTMLInputElement>);
                      setTempNumberOfTickets(Number(e.target.value));
                    }}
                  />
                </div>
                <button
                  {...handleinteractions(() => {
                    debouncedHandleButtonPress(tempNumberOfTickets + 1);
                    handleTempNumberOfTickets(+1);
                  })}
                  className={classNames(
                    'h-10 w-10 border border-primary-main text-primary-main bg-green-700  text-white rounded-md grid place-content-center text-2xl',
                    tempNumberOfTickets >= TICKET_PURCHASE_LIMIT
                      ? 'pointer-events-none opacity-30'
                      : '',
                  )}
                >
                  &#43;
                </button>
              </div>
            </div>
            <div className="relative">
              {(ticketMissmatch || loading) && (
                <div className="absolute inset-0 z-1 grid place-content-center">
                  <Spinner />
                </div>
              )}
              <div
                className={classNames('flex flex-col gap-5', ticketMissmatch ? 'opacity-50' : '')}
              >
                <div className="p-4">
                  <button
                    className={classNames(
                      'w-full p-2 bg-black text-white text-sm font-medium rounded-md mb-2',
                      loading || ticketMissmatch ? 'opacity-50' : '',
                    )}
                    disabled={loading || ticketMissmatch}
                    {...handleinteractions(handleSwish)}
                  >
                    <img className="w-6 inline-block mr-2" src="/swish.png" alt="Swish" />
                    Betala med Swish
                  </button>

                  {paymentIntent &&
                    !(loading || ticketMissmatch) &&
                    (paymentIntent.isTest ? (
                      <>
                        <Elements options={options} stripe={stripeTestPromise}>
                          <CheckoutFormExpress
                            onConfirm={handleConfirm}
                            paymentIntent={paymentIntent.paymentIntent}
                          />
                          <CheckoutFormElements
                            onConfirm={handleConfirm}
                            paymentIntent={paymentIntent.paymentIntent}
                            showCard={showCard}
                            setShowCard={setShowCard}
                          />
                        </Elements>
                      </>
                    ) : (
                      <>
                        <Elements options={options} stripe={stripePromise}>
                          <CheckoutFormExpress
                            onConfirm={handleConfirm}
                            paymentIntent={paymentIntent.paymentIntent}
                          />
                          <CheckoutFormElements
                            onConfirm={handleConfirm}
                            paymentIntent={paymentIntent.paymentIntent}
                            showCard={showCard}
                            setShowCard={setShowCard}
                          />
                        </Elements>
                      </>
                    ))}
                  <button
                    className="w-full mt-2 px-4 bg-white border-2 border-black text-black text-lg rounded-md flex justify-center gap-2 items-center"
                    disabled={loading || ticketMissmatch}
                    {...handleinteractions(() => {
                      setLoading(true);
                      window.location.href = checkoutSession.checkoutSession.url;
                    })}
                  >
                    <CreditCardIcon className="h-6 mr-2" aria-hidden="true" />
                    <img src="/apple-pay-mark.svg" className="h-5" alt="Apple Pay" />
                    <img src="/google-pay-mark.svg" className="h-10" alt="Google Pay" />
                    <img src="/klarna-badge.png" className="h-9 -ml-3" alt="Klarna" />
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="p-8">
            <h5 className="text-xl font-medium text-black mb-5">
              {t('dashboard.enter_payment_code')}
            </h5>
            <form
              className="flex flex-col gap-5"
              onSubmit={(e) => {
                e.preventDefault();
                if (paymentCodeError && setPaymentCodeError) setPaymentCodeError(false);
                plausible.trackPageview({ url: `${window.location.origin}/${paymentCode}` });
                trackEvent('manual-payment-code-entered');
                handlePaymentCode(paymentCode || selectedFacility?.paymentCode || '');
              }}
            >
              <Combobox
                as="div"
                value={selectedFacility}
                onChange={(facility) => {
                  setQuery('');
                  if (paymentCodeError && setPaymentCodeError) setPaymentCodeError(false);
                  setSelectedFacility(facility as FacilityForPayments);
                  setPaymentCode((facility as FacilityForPayments).paymentCode);
                }}
              >
                <div className="relative mt-2">
                  <ComboboxInput
                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                    onChange={(event) => {
                      if (paymentCodeError && setPaymentCodeError) setPaymentCodeError(false);
                      setQuery(event.target.value);
                    }}
                    onBlur={() => setQuery('')}
                    placeholder="Search by name or code"
                    displayValue={(person: { name: string; username: string } | null) =>
                      person?.name || ''
                    }
                  />
                  <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </ComboboxButton>

                  {filteredFacilities.length > 0 && (
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {filteredFacilities?.map((facility) => (
                        <ComboboxOption
                          key={facility.id}
                          value={facility}
                          className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
                        >
                          <div className="flex">
                            <span className="truncate group-data-[selected]:font-semibold">
                              {facility.name}
                            </span>
                            <span className="ml-2 truncate text-gray-500 group-data-[focus]:text-indigo-200">
                              {facility.paymentCode}
                            </span>
                          </div>

                          <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  )}
                </div>
              </Combobox>
              {paymentCodeError ? (
                <div>
                  {selectedFacility?.paymentsActive ? (
                    <p className="mt-2 text-sm text-red-600">Not a valid code.</p>
                  ) : (
                    <p className="mt-2 text-sm text-red-600">
                      Payments are not active for this facility.
                    </p>
                  )}
                </div>
              ) : undefined}
              <button
                type="submit"
                className="w-full py-2 px-2 bg-black text-white text-lg font-medium rounded-md flex items-center justify-between hover:bg-gray-600 focus:outline focus:ring-2 focus:ring-gray-600"
              >
                {t('dashboard.continue')}
                <CreditCardIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </form>
            {prefillFacility && prefillPaymentCode && (
              <>
                <br />
                <span className="text-sm text-gray-500">
                  {t('dashboard.continue_with_latest')}:{' '}
                </span>
                <button
                  className="w-full py-2 px-2 bg-white text-black text-sm font-medium rounded-md flex items-center justify-between hover:bg-gray-600 focus:outline focus:ring-2 focus:ring-gray-600 border border-black"
                  onClick={() => {
                    setSelectedFacility(prefillFacility);
                    setPaymentCode(prefillPaymentCode);
                    handlePaymentCode(prefillPaymentCode);
                  }}
                >
                  {prefillFacility.name}
                  <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};
